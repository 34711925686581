import './style.css'
import * as THREE from 'three'
// import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {VRMLoaderPlugin} from '@pixiv/three-vrm';
import {loadMixamoAnimation} from './loadMixamoAnimation.js';
// Import Swiper JS
import Swiper from 'swiper';
// Import Swiper styles
import 'swiper/css';
// Import modules
import {Navigation, EffectCoverflow} from 'swiper/modules';
// Import module styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import modelData from './modeldata.json';
import animationsData from './animationsdata.json';


const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

//解析模型json
function getModelName(gender, index) {
    // 检查性别和索引是否有效
    if ((gender !== 'Female' && gender !== 'Male' && gender !== 'Other') || index < 0 || index >= modelData[gender].length) {
        return null;  // 无效的性别或索引
    }

    return modelData[gender][index];
}

let gender = 'Male';    //可以是 Female，Male，Other
let index = 0;       // 从0开始计数
const modelName = getModelName(gender, index);

// 根据指定性别加载Swiper的列表
function createSwiperSlidesFromModelData(gender) {
    const swiperContainer = document.querySelector('.swiper-container .swiper-wrapper');

    // 首先清除当前的swiper列表
    swiperContainer.innerHTML = '';

    if (modelData[gender]) {
        modelData[gender].forEach(modelName => {
            // 移除.vrm后缀
            const modelNameWithoutExtension = modelName.replace('.vrm', '');

            // 为每个模型创建一个swiper-slide
            const slideDiv = document.createElement('div');
            slideDiv.classList.add('swiper-slide');

            const img = document.createElement('img');
            img.src = `./textures/${gender}/${modelNameWithoutExtension}.jpg`;
            img.alt = `Image ${modelNameWithoutExtension}`;

            slideDiv.appendChild(img);
            swiperContainer.appendChild(slideDiv);
        });
    }
}

// 切换性别并重新加载Swiper的列表
let isChangingGender = false; // 初始设置为 true
function switchGender(newGender, index = 0) {
    if (newGender === 'Female' || newGender === 'Male' || newGender === 'Other') {
        isChangingGender = true; // 初始设置为 true

        gender = newGender;
        currentIndex = 0;
        createSwiperSlidesFromModelData(newGender);

        // 更新Swiper
        swiper.update();

        initDots();

        const modelName = modelData[gender][index];
        loadVRMModel('models/' + gender + '/' + modelName);
        swiper.slideTo(index);

        isChangingGender = false;
    }
}

// 初始加载
createSwiperSlidesFromModelData(gender);


// 安装Swiper模块
Swiper.use([Navigation, EffectCoverflow]);
const swiper = new Swiper('.swiper-container', {
    direction: 'horizontal',
    loop: false, // 循环模式
    centeredSlides: true,
    slidesPerView: 5,     // 同时显示5张图片
    effect: 'coverflow',  // 使用Coverflow效果
    spaceBetween: 14,
    coverflowEffect: {
        rotate: 0,        // 图片之间的旋转角度
        stretch: 0,       // 图片之间的拉伸值
        depth: 100,       // 深度值
        modifier: 1,      // 影响其他的滑块
        slideShadows: false, // 开启阴影
    },
    initialSlide: 0,
});
swiper.slideTo(0);

//解析动画json
function getAnimationList(gender) {
    if (animationsData.hasOwnProperty(gender)) {
        return animationsData[gender];
    } else {
        console.error("无效的性别: ", gender);
        return [];
    }
}

// 示例使用
const femaleAnimations = getAnimationList("Female");
// console.log(femaleAnimations);  // 输出: ["Idle.fbx", "Standing.fbx", ...]

// Canvas
const canvas = document.querySelector('canvas.webgl')

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color(0x010101);

//  camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.y = 3
camera.position.z = -2.8
scene.add(camera)

// white light
const light = new THREE.AmbientLight(0xffffff, 1);
scene.add(light);

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true
// controls.enableZoom = false; // 禁用缩放功能
// // 限制只能左右旋转
// controls.minPolarAngle = Math.PI / 2 - 0.35;
// controls.maxPolarAngle = Math.PI / 2 - 0.35;
// controls.target.set(0, 2, 0);

//Model
let currentVrm = undefined;
let currentAnimationUrl = './animations/' + femaleAnimations[0];//'./animations/Idle.fbx';
let currentMixer = undefined;
let currentClip = undefined;
let currentVrmUrl = undefined;
let currentGender = undefined;

const loader = new GLTFLoader();
loader.register((parser) => {
    return new VRMLoaderPlugin(parser);
});

let progressBarContainer = document.querySelector('.progress-container');
let progressBar = document.querySelector('.progress-bar');

function updateProgressBar(percentage) {
    progressBar.style.width = percentage + '%';
}

let isLoading = false; // 新增的全局变量

function loadVRMModel(url) {
    // console.log("加载请求：", url);

    if (isLoading) {
        console.log("Model is already being loaded. Please wait.");
        return; // 如果模型正在加载，那么直接返回
    }
    if (currentVrmUrl === url && currentGender === gender)
        return;

    isLoading = true; // 设置加载标志为true

    currentVrmUrl = url;
    currentGender = gender;
    progressBarContainer.style.display = 'block';
    document.getElementById('overlay').style.display = 'block';

    // 1. 从场景中删除旧模型
    if (currentVrm && currentVrm.scene) {
        scene.remove(currentVrm.scene);
        currentVrm = undefined;
    }

    loader.load(
        url,

        (gltf) => {
            currentVrm = gltf.userData.vrm;

            if (gender === "Other") {
                currentMixer = null;
                currentClip = null;

                camera.position.x = 0
                camera.position.y = 1.7
                camera.position.z = -4.2

                camera.lookAt(new THREE.Vector3(0, 0.8, 0));

                isFixedCamera = true;

                scene.add(currentVrm.scene);
                document.getElementById('overlay').style.display = 'none';
                progressBarContainer.style.display = 'none';
            } else
                // 4. 应用动画到新模型
                loadFBX(currentAnimationUrl);


            // console.log("加载完成：", currentVrm);
            isLoading = false; // 加载完成，设置标志为false

        },

        (progress) => {
            let percentageLoaded = 90.0 * (progress.loaded / progress.total);
            // console.log('Loading model...', percentageLoaded, '%');
            updateProgressBar(percentageLoaded);
        },

        (error) => {
            console.error(error);
            document.getElementById('overlay').style.display = 'none';
            isLoading = false; // 加载完成，设置标志为false
        },
    );

}

// loadVRMModel('http://eevee.com.cn/Temp/39728534434098557001.vrm');

// function loadVRMModelLocal() {
//     loadVRMModel('models/' + gender + '/' + modelName);
// }
//
// loadVRMModelLocal()

let isFixedCamera = false;

function loadFBX(animationUrl) {
    currentMixer = new THREE.AnimationMixer(currentVrm.scene);
    // Load animation
    loadMixamoAnimation(animationUrl, currentVrm).then((clip) => {
        currentClip = clip;
        // Apply the loaded animation to mixer and play
        currentMixer.clipAction(clip).play();
        updateProgressBar(100);
        setTimeout(() => {
            // let posecenter = currentVrm.scene.getObjectByName("Root").getObjectByName("J_Bip_C_Hips");
            // console.log("posecenter", posecenter);
            // let worldPosition = new THREE.Vector3();
            // posecenter.getWorldPosition(worldPosition);
            // //下移模型
            // worldPosition.y += 0.0;

            // controls.target.copy(worldPosition);
            camera.position.x = 0
            camera.position.y = 1.8
            camera.position.z = -4.2
            //拉近镜头
            // let offset = new THREE.Vector3(); // 定义一个偏移量
            // let distance = -0.2; // 期望靠近的距离
            camera.lookAt(new THREE.Vector3(0, 0.0, 0));
            // offset.subVectors(camera.position, controls.target).normalize().multiplyScalar(distance);
            // camera.position.sub(offset);

            isFixedCamera = true;


            scene.add(currentVrm.scene);
            document.getElementById('overlay').style.display = 'none';
            progressBarContainer.style.display = 'none';
        }, 2);


    });
}

/*Update*/
const clock = new THREE.Clock()

const tick = () => {
    const deltaTime = clock.getDelta();
    if (currentMixer) {
        // update the animation
        currentMixer.update(deltaTime);
    }
    if (currentVrm) {
        currentVrm.update(deltaTime);
    }

    // controls.update()
    renderer.render(scene, camera)

    window.requestAnimationFrame(tick)
}

tick()

let isDragging = false;
let lastX;
// 触摸事件
canvas.addEventListener('touchstart', (e) => {
    isDragging = true;
    lastX = e.touches[0].clientX;  // 使用第一个触摸点的坐标
    e.stopPropagation();
});

canvas.addEventListener('touchend', (e) => {
    isDragging = false;  // 当触摸结束时停止拖动
    e.stopPropagation();
});

canvas.addEventListener('touchmove', (e) => {
    if (isDragging) {
        const deltaX = e.touches[0].clientX - lastX;  // 使用第一个触摸点的坐标
        if (currentVrm) {
            currentVrm.scene.rotation.y += deltaX * 0.01;
        }
        lastX = e.touches[0].clientX;
        e.stopPropagation();
    }
});

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/* 顶部性别切换按钮 */
document.getElementById('buttonBoy').addEventListener('click', function () {
    this.classList.add('selected');
    document.getElementById('buttonGirl').classList.remove('selected');
    document.getElementById('buttonOther').classList.remove('selected');
    switchGender("Male")
});

document.getElementById('buttonGirl').addEventListener('click', function () {
    this.classList.add('selected');
    document.getElementById('buttonBoy').classList.remove('selected');
    document.getElementById('buttonOther').classList.remove('selected');
    switchGender("Female")
});

document.getElementById('buttonOther').addEventListener('click', function () {
    this.classList.add('selected');
    document.getElementById('buttonBoy').classList.remove('selected');
    document.getElementById('buttonGirl').classList.remove('selected');
    switchGender("Other")

});


/* 中间切换按钮 */
document.querySelector('.swiper-prev').addEventListener('click', () => {
    swiper.slidePrev();
});

document.querySelector('.swiper-next').addEventListener('click', () => {
    swiper.slideNext();
});

/* 完成按钮 */
document.querySelector('.next-button').addEventListener('click', () => {
    let obj = {
        gender: gender === 'Male' ? '0' : gender === 'Female' ? '1' : '2',
        index: currentIndex
    };

    let jsonStr = JSON.stringify(obj);
    // fetch("/HtmlToiOS?param=" + jsonStr);
    window.webkit.messageHandlers.messageHandler.postMessage(jsonStr);

});

/* 底部切换原点 */
let slider = document.querySelector('.dots-container');
let dots = document.querySelectorAll('.dot');
let totalDots = dots.length;
let currentIndex = 0; // 默认选中中间的圆点
let dotWidth = 8; // 单个圆点的宽度
let dotGap = 15; // 圆点之间的间隔
let wrapperWidth = totalDots * dotWidth + (totalDots - 1) * dotGap; // 总宽度
// 设置dots-wrapper的宽度
let wrapper = document.querySelector('.dots-wrapper');

let shouldLoadModel = true; // 初始设置为 true

function updateActiveDot(isswiper = true) {
    // 先重置所有圆点颜色
    dots.forEach(dot => dot.classList.remove('active'));

    // 设置活动圆点的颜色
    dots[currentIndex].classList.add('active');

    // 计算mask的中心到dots-wrapper左边的距离
    let maskWidth = parseInt(getComputedStyle(document.querySelector('.mask')).width);
    let maskCenter = maskWidth / 2;

    // 计算从dots-wrapper的左边到选中点中心的距离
    let distanceToSelectedDotCenter = currentIndex * (dotWidth + dotGap) + dotWidth / 2;

    // 计算总的偏移量，使得选中的点中心与mask的中心对齐
    let totalOffset = maskCenter - distanceToSelectedDotCenter;

    wrapper.style.transform = `translateX(${totalOffset}px)`;

    // 更新 .animation-name 的内容
    // const animationNameWithExtension = animationsData[gender][currentIndex];
    // const animationNameWithoutExtension = animationNameWithExtension.split('.')[0]; // 分割字符串并只获取前面的部分
    // animationNameElement.textContent = "#" + animationNameWithoutExtension;

    //更新swiper
    if (isswiper)
        swiper.slideTo(currentIndex);

}

function initDots() {

// 获取 .dots-wrapper 的引用
    const dotsWrapper = document.querySelector('.dots-wrapper');

// 清空 .dots-wrapper 的内容
    dotsWrapper.innerHTML = '';

    // console.log("initDots:", modelData[gender].length)
// 根据 modelData 动态创建圆点
    modelData[gender].forEach(animation => {
        const dotElement = document.createElement('div');
        dotElement.classList.add('dot');
        dotsWrapper.appendChild(dotElement);
    });
    dots = document.querySelectorAll('.dot');
    totalDots = dots.length;
    wrapperWidth = totalDots * dotWidth + (totalDots - 1) * dotGap;
    currentIndex = 0;
    wrapper.style.width = `${wrapperWidth}px`;

    shouldLoadModel = true;
// 在初始化时，设置中间的圆点为活动状态
    updateActiveDot();
}

initDots();

let startX;

slider.addEventListener('touchstart', (e) => {
    shouldLoadModel = false;

    slider.classList.add('show-before'); // 显示 ::before 伪元素
    startX = e.touches[0].clientX;
    slider.addEventListener('touchmove', touchMove);
}, {passive: false});


function touchMove(ev) {
    let moveX = ev.touches[0].clientX;

    if (moveX > startX + 30 && currentIndex > 0) { // 向右滑动
        currentIndex--;
        updateActiveDot();
        startX = moveX;
    } else if (moveX < startX - 30 && currentIndex < totalDots - 1) { // 向左滑动
        currentIndex++;
        updateActiveDot();
        startX = moveX;
    }
}

slider.addEventListener('touchend', () => {
    slider.classList.remove('show-before'); // 隐藏 ::before 伪元素
    // 移除touchmove事件监听，避免多次绑定
    slider.removeEventListener('touchmove', touchMove);
});

// 对于非触摸设备，我们还可以监听mousedown和mouseup事件
slider.addEventListener('mousedown', () => {
    slider.classList.add('show-before');
});

slider.addEventListener('mouseup', () => {
    slider.classList.remove('show-before');
});

//切换加载模型
slider.addEventListener('touchend', () => {

    shouldLoadModel = true; // 完成滑动后，我们希望加载模型
    slider.classList.remove('show-before'); // 隐藏 ::before 伪元素
    slider.removeEventListener('touchmove', touchMove);

    // 切换加载模型的逻辑
    const modelName = modelData[gender][currentIndex];
    if (modelName) {
        loadVRMModel('models/' + gender + '/' + modelName);
    }
});


//切换加载模型
swiper.on('transitionEnd', function () {
    if (this.previousIndex !== this.activeIndex && shouldLoadModel && !isChangingGender) {  // 确保滑动已经发生
        // console.log('slide changed to index:', this.activeIndex);

        // 根据this.activeIndex决定加载哪个模型
        const modelName = modelData[gender][this.activeIndex];
        if (modelName) {
            loadVRMModel('models/' + gender + '/' + modelName);
        }
    }
});

//同步圆点
swiper.on('slideChange', function () {
    currentIndex = this.activeIndex;
    updateActiveDot(false); // 假设你已经修改了 updateActiveDot 函数以接受一个参数
});


// //拖入修改动画
// // end handler
// window.addEventListener('dragover', function (event) {
//     event.preventDefault();
// });
//
// window.addEventListener('drop', function (event) {
//
//     event.preventDefault();
//
//     // read given file then convert it to blob url
//     const files = event.dataTransfer.files;
//     if (!files) return;
//
//     const file = files[0];
//     if (!file) return;
//
//     const fileType = file.name.split('.').pop();
//     const blob = new Blob([file], {type: 'application/octet-stream'});
//     const url = URL.createObjectURL(blob);
//
//     if (fileType === 'fbx') {
//         currentAnimationUrl = url;
//         loadFBX(url);
//
//     }
//
// });

/* 与iOS交互 */
window.IosToHtml = function (value) {
    // console.log("H5消息:", value)
    // fetch("/HtmlToiOS?param=H5消息:" + value);

    // 使用 JSON.parse 方法解析 JSON 字符串为 JavaScript 对象
    const parsedData = JSON.parse(value);
    // fetch("/HtmlToiOS?param=H5消息gender:" + parsedData.gender);
    // fetch("/HtmlToiOS?param=H5消息index:" + parsedData.index);
    if (parsedData.gender === "0") {
        gender = "Male";
        document.getElementById('buttonBoy').classList.add('selected');
        document.getElementById('buttonGirl').classList.remove('selected');
        document.getElementById('buttonOther').classList.remove('selected');
    } else if (parsedData.gender === "1") {
        gender = "Female";
        document.getElementById('buttonGirl').classList.add('selected');
        document.getElementById('buttonBoy').classList.remove('selected');
        document.getElementById('buttonOther').classList.remove('selected');
    } else if (parsedData.gender === "2") {
        gender = "Other";
        document.getElementById('buttonOther').classList.add('selected');
        document.getElementById('buttonGirl').classList.remove('selected');
        document.getElementById('buttonBoy').classList.remove('selected');
    }

    currentIndex = parsedData.index;
    // const modelName = modelData[gender][currentIndex];
    // // 使用解析后的数据来加载模型
    // loadVRMModel('models/' + gender + '/' + modelName);
    switchGender(gender, currentIndex);
};
//
// if (window && window.webkit && window.webkit.messageHandlers)
//     window.webkit.messageHandlers.getUserInfoBeforeLoaded.postMessage("H5消息:WebglLoaded!");
//
//
// if (window.webkit && window.webkit.messageHandlers)
//     console.log = function (message) {
//         window.webkit.messageHandlers.logHandler.postMessage(message);
//     };
//
// fetch("/HtmlToiOS?param=WebglLoaded!");
window.webkit.messageHandlers.messageHandler.postMessage("WebglLoaded!");
